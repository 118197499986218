import { FC, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import classes from "./style";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getPayResult, getShopPrice, payShop } from "../../services/payService";
import { ShopItemCard } from "./ShopItemCard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store";
import {
  PaiementResultType,
  PayCommand,
  ReservationResultType,
  ShoppingPrice,
} from "../../models/Pay";
import { ADD_TO_CARD, LOAD_CARD } from "../../slices/reservationSlice";
import { addPacktoShop } from "../../services/reservationService";
import { AddPackType } from "../../models/ReservationProposal";
import { LoggedType } from "../../slices/loginSlice";
import { needConfirmation, showMessage } from "../../services/popupService";
import { getReduction } from "../../services/cardService";
import { Reduction } from "../../models/Reduction";
import { getReductionValue } from "../../utils/manyUtils";
import { PurchasedPack } from "../../models/Pack";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { checkClientAI } from "../../services/clientService";

export interface ShoppingProps {
  onClose: () => void;
}

let canClientUseAI = false;

export const Shopping: React.FC<ShoppingProps> = ({
  onClose,
}: ShoppingProps) => {
  const state = useSelector((s: RootState) => s);
  const dispatch = useDispatch();

  const [init, setInit] = useState(false);
  const [paying, setPaying] = useState(false);
  const history = useHistory();

  const [withAI, setWithAI] = useState(false);

  const [reductionCode, setReduction] = useState<Reduction>();
  const [choosedReduction, setChoosedReduction] = useState<string>("");
  const [checking, setChecking] = useState(false);

  const [invalid, setInvalid] = useState(false);
  const [needClose, setNeedClose] = useState(false);

  const [price, setPrice] = useState<ShoppingPrice>();
  const [openedId, setOpenedId] = useState<number>(0);

  const redirect = async () => {
    onClose();
    history.push("/acces-membre?redirect=shop");
  };

  const pay = () => {
    if (state.reservation.card.length === 0) return;
    if (state.login.loggedType === LoggedType.None) {
      needConfirmation(
        "Vous devez être connecté pour payer !",
        () => redirect(),
        () => { },
        "Créer un compte",
        "Annuler"
      );
    } else if (state.login.loggedType == LoggedType.Client) {
      payCards();
    }
  };

  const setOpening = (id: number) => {
    setOpenedId(openedId == id ? 0 : id);
  };

  const isOpened = (id: number) => {
    return openedId == id;
  };

  const checkCode = async () => {
    if (checking) return;
    setChecking(true);
    setInvalid(false);

    try {
      const r = await getReduction(choosedReduction);

      if (r) {
        setReduction(r);
        setInvalid(false);
      } else {
        setInvalid(true);
      }
    } catch {

    }

    setChecking(false);
  };

  const payCards = async () => {
    if (state.reservation.card.length == 0) return;

    if (paying) return;
    setPaying(true);
    setInvalid(false);

    try {
      const command: PayCommand = {
        reductionCode: reductionCode?.code ?? "",
        withAI: withAI
      };

      const res = await payShop(command);

      dispatch(LOAD_CARD());

      if (res.resultType === ReservationResultType.Success) {
        if (res.url.startsWith("pack") || res.url.startsWith("ai")) {
          onClose();
          retrievePayment(res.url.substring((res.url.startsWith("pack") ? "pack/" : "ai/").length));
        } else {
          window.location.replace(res.url);
        }
      } else {
        let msg = '';

        switch (res.resultType) {
          case ReservationResultType.Error:
            msg = 'Une erreur est survenue lors du paiement.';
            break;
          case ReservationResultType.NoItem:
            msg = 'Votre panier est vide.';
            break;
          case ReservationResultType.NotLogged:
            msg = `Vous devez être connecté.`;
            break;
          case ReservationResultType.TooClose:
            msg = 'Vous ne pouvez pas réserver de séance à moins de 4 heures de l\'heure de réservation.';
            break;
          case ReservationResultType.TooCloseAI:
            msg = 'Vous ne pouvez pas réserver de séance à moins de 24 heures de l\'heure de réservation en utilisant l\'avance immédiate.';
            break;
          default:
            msg = 'Une erreur est survenue lors du paiement.'; break;
        }

        showMessage(msg);
      }
    } catch {

    }

    setPaying(false);
  };

  const retrievePayment = async (refId: string) => {
    try {
      const pay = await getPayResult(refId);
      if (pay?.result === PaiementResultType.Success) {
        if (pay.withAI) {
          const s = 'Votre séance est réservée, elle doit être validée par votre coach. (Confirmation par mail). Vous recevrez un lien de paiement de l’URSSAF une fois la séance effectuée.';
          showMessage(s);
        }
        else if (pay.packUsed > 0 || pay.finalPrice > 0) {
          const s = 'Votre réservation a bien été effectuée ! '
            + (pay.packUsed > 0 ? 'Packs utilisés : ' + pay.packUsed : '');

          showMessage(s);
        }
      } else {
        showMessage('Votre réservation a été annulé.');
      }
    } catch {

    }
  }

  const addPack = async () => {
    if (paying) return;
    setPaying(true);

    try {
      const r = await addPacktoShop();

      if (r.result == AddPackType.OK) {
        if (state.login.loggedType === LoggedType.Client) {
          dispatch(LOAD_CARD());
        } else {
          const pack = r.proposal as PurchasedPack;
          if (pack !== undefined) {
            dispatch(
              ADD_TO_CARD({
                id: 1,
                addDate: pack.purchaseDate,
                packId: pack.id,
              })
            );
          }
        }

        setWithAI(false);
      }
    } catch (e) {
      showMessage("Une erreur est survenue.");
    }

    setPaying(false);
  };

  const cantUseAIText = "Pour bénéficier de l'avance immédiate, votre compte Urssaf doit être crée. Pour cela, rendez vous dans votre profil.";

  const reductionValue = reductionCode
    ? getReductionValue(reductionCode.value) + "%"
    : "";

  // const refreshWithAI = (canShopAI: boolean) => {
  //   const isValid = canClientUseAI && canShopAI;
  //   if (!isValid) {
  //     setWithAI(false);
  //     setInit(false);
  //   } else if (!init) {
  //     setInit(true);
  //     setWithAI(true);
  //   }
  // }

  const getPrice = async () => {
    try {
      const p = await getShopPrice(state.reservation.card, reductionCode?.code ?? "", withAI);
      setPrice(p);
    } catch {

    }
  };

  // const getAI = async() => {
  //   try {
  //     if (state.login.loggedType === LoggedType.Client) {
  //       canClientUseAI = await checkClientAI();
  //     }
  //   } catch {

  //   }
  // }

  useEffect(() => {
    getPrice();
  }, [
    state.reservation.card.length,
    state.reservation.packs.length,
    reductionCode,
    withAI
  ]);

  const firstRetrieve = async () => {
    if (state.login.loggedType === LoggedType.Client) {
      canClientUseAI = await checkClientAI();
    }

    setWithAI(canClientUseAI);
  }

  useEffect(() => {
    if (needClose) onClose();
  }, [needClose]);

  useEffect(() => {
    // getAI();
    firstRetrieve();
  }, []);

  return (
    <Box sx={classes.shopping}>
      <Box>
        {state.reservation.card.length === 0 ? (
          <Typography>Votre pannier est vide.</Typography>
        ) : (
          <>
            <Table
              sx={{
                minWidth: 650,
                borderCollapse: "separate",
                borderSpacing: "0 15px",
                border: "transparent",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={classes.label}>Produit</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.reservation.card.map((c, i) => (
                  <ShopItemCard
                    key={i}
                    item={c}
                    opening={(j) => setOpening(j)}
                    open={isOpened(c.id)}
                  />
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell
                    colSpan={2}
                    sx={{ ...classes.label, py: 2, px: 0 }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={[classes.newPrice, { fontSize: "26px" }]}
                  >
                    {price && price.price.default !== price.price.final ? (
                      <Typography
                        sx={[
                          classes.firstPrice,
                          {
                            fontSize: "18px",
                            width: "fit-content",
                            margin: "auto",
                          },
                        ]}
                      >
                        {price && price.price ? `${price.price.default}€` : 0}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography
                      sx={[
                        classes.newPrice,
                        {
                          fontSize: "18px",
                          width: "fit-content",
                          margin: "auto",
                        },
                      ]}
                    >
                      {price && price.price ? `${price.price.final}€` : 0}
                    </Typography>
                    {price && price.packUsed !== 0 ? (
                      <Typography sx={[classes.newPrice, { fontSize: "18px" }]}>
                        Packs utilisés: {price.packUsed}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </Box>
      <Grid container alignItems="end" mb={3}>
        <Grid item xs={12} sm={6}>
          {!reductionCode ? (
            invalid ? (
              <Typography color="error">Ce code de reduction est invalide.</Typography>
            ) : (
              ""
            )
          ) : (
            <Typography color="primary">
              Reduction de {reductionValue} sur une séance
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            id="code"
            value={choosedReduction}
            label="Code de reduction"
            variant="standard"
            sx={{ mr: 1 }}
            onChange={(e: any) => setChoosedReduction(e.target.value!)}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <Button
            disabled={checking || (choosedReduction?.length ?? 0) === 0}
            onClick={() => checkCode()}
            variant="outlined"
          >
            {checking ? <CircularProgress /> : <Typography>Valider</Typography>}
          </Button>
        </Grid>
        <Grid item container sm={12} textAlign="center" direction='row' style={{ marginTop: '5px', justifyContent: 'center' }}>
          <Grid item xs={2}>
            <Typography style={{ marginTop: '8px' }}>Avance immédiate</Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox checked={withAI} onChange={(e) => setWithAI(e.target.checked!)} disabled={!canClientUseAI || !(price?.canAI ?? false)} />
          </Grid>
          {
            canClientUseAI ? '' :
              (
                <Grid item xs={1} style={{ marginTop: '8px' }}>
                  <Tooltip
                    enterTouchDelay={0}
                    title={cantUseAIText}
                    sx={{ ml: 1 }}
                  >
                    <InfoOutlined />
                  </Tooltip>
                </Grid>
              )
          }
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            disabled={state.reservation.card.length === 0}
            onClick={() => pay()}
            sx={[classes.submitBtn, { mt: 2, mb: 0, textAlign: "center" }]}
          >
            Valider le panier
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Shopping;
