import HoplaTheme from "./theme";

const classes = {
  select: {
    width: "100%",
    "& .MuiSelect-select": {
      color: 'white',
      "&:focus": {
        backgroundColor: "transparent",
      },
      "& .MuiInputBase-root:before": {
        borderBottom: "1px solid white !important",
      },
    },
    "& .MuiInput-root:before, .MuiInput-root:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid white !important",
    },
    "& .MuiSvgIcon-root": {
      color: '#104f5c',
    },
  },
  selectMenuProps: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .MuiMenu-list": {
      marginTop: "15px",
      borderRadius: "5px",
      border: "2px solid #104f5c !important",
      py: 0,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      "& .MuiButtonBase-root": {
        background: "#FFF",
        "&:hover": {
          color: '#104f5c',
        },
        "&:first-of-type": {
          paddingTop: 1,
        },
        "&:last-of-type": {
          paddingBottom: 1,
        },
      },
    },
    "& .Mui-selected": {
      color: '#104f5c',
      background: "#FFF !important",
      "&:hover": {
        background: "#FFF !important",
      },
    },
    "&:after": {
      position: "absolute",
      zIndex: 9999,
      content: '""',
      left: "20px",
      top: "0px",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "16px solid #104f5c",
    },
  },
  submitBtn: {
    borderRadius: "50px",
    backgroundColor: '#8C6AD4',
    color: HoplaTheme.custom.White,
    width: '80%',
    px: 3,
    "&:hover": {
      backgroundColor: '#8C6AD4',
      color: HoplaTheme.custom.White,
    },
  },
  backBtn: {
    color: "#000 !important",
    border: "none !important",
  },
  label: {
    fontSize: "16px",
  },
  input: {
    "&.MuiFormControl-root": {
      width: "100%",
      backgroundColor: 'transparent !important'
    },
    "& .MuiFilledInput-input": {
      backgroundColor: 'transparent !important',
      py: 0.5,
    },
    "& .MuiFilledInput-root": {
      backgroundColor: 'transparent !important',
    },
  },
  customBox: {
    p: 4,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "25px",
    border: `2px solid ${HoplaTheme.palette.primary.main}`,
  },
  price: {
    fontSize: "40px !important",
  },
  cellBorder: {
    [HoplaTheme.breakpoints.up("md")]: {
      borderLeft: `2px solid ${HoplaTheme.palette.primary.main}`,
    },
  },
  priceBarre: {
    position: "relative",
    "&::before": {
      position: "absolute",
      content: "''",
      left: "-5px",
      top: "36%",
      right: "5px",
      borderTop: "2px solid",
      borderColor: HoplaTheme.custom.Black,
      "-webkit-transform": "rotate(-20deg)",
      "-moz-transform": "rotate(-20deg)",
      "-ms-transform": "rotate(-20deg)",
      "-o-transform": "rotate(-20deg)",
      transform: "rotate(-20deg)",
    },
  },
  firstPrice: {
    fontSize: "22px",
    color: HoplaTheme.palette.secondary.main,
    position: "relative",
    "&::before": {
      position: "absolute",
      content: "''",
      left: "-5px",
      top: "36%",
      right: "5px",
      borderTop: "2px solid",
      borderColor: HoplaTheme.custom.Black,
      "-webkit-transform": "rotate(-20deg)",
      "-moz-transform": "rotate(-20deg)",
      "-ms-transform": "rotate(-20deg)",
      "-o-transform": "rotate(-20deg)",
      transform: "rotate(-20deg)",
    },
  },
  newPrice: {
    fontSize: "22px",
    color: HoplaTheme.palette.primary.main,
  },
  /** Recherche séance */
  rechercheSeance: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    background: "rgba(0, 0, 0, .8) url('main-background.png')",
    backgroundBlendMode: "darken",
    backgroundSize: "cover",
    "& h1": {
      color: HoplaTheme.custom.White,
      [HoplaTheme.breakpoints.up("sm")]: {
        pt: 4,
      },
      "& span": {
        "&:nth-of-type(2n)": {
          color: HoplaTheme.palette.secondary.main,
        },
        "&:nth-of-type(2n + 1)": {
          color: HoplaTheme.palette.primary.main,
        },
      },
    },
    "& .MuiContainer-root": {
      zIndex: 99,
      py: 6,
    },
    "& .MuiContainer-root:nth-of-type(2)": {
      zIndex: 999,
      [HoplaTheme.breakpoints.down("sm")]: {
        pt: 0,
        pb: 3,
      },
      "& .MuiGrid-root": {
        backgroundColor: HoplaTheme.custom.White,
        borderRadius: "25px",
        py: 1,
        px: 1.5,
        "& .MuiTypography-body1": {
          color: HoplaTheme.palette.primary.main,
        },
        "& .MuiFormControlLabel-root": {
          my: 1,
          "& .MuiTypography-body1": {
            fontSize: "14px",
            color: HoplaTheme.custom.Black,
          },
        },
      },
    },
    "& .mask": {
      position: "absolute",
      height: "100%",
      width: "100%",
      backgroundColor: HoplaTheme.custom.Black,
      opacity: 0.5,
    },
    "& .dispoDate": {
      my: 1,
      fontSize: "14px",
    },
    "& .dispoHours": {
      padding: "0 !important",
      "& .MuiGrid-root": {
        padding: "0 !important",
      },
      "& .MuiButton-root": {
        py: 0.3,
        my: 0.5,
        borderRadius: "10px",
      },
      "& .MuiBadge-badge": {
        p: 0,
        backgroundColor: 'transparent',//HoplaTheme.custom.White,
        border: `0px solid ${HoplaTheme.palette.error.main}`,
        "& .MuiSvgIcon-root": {
          fontSize: "14px",
          color: 'white', //HoplaTheme.palette.error.main,
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
  },
  /** Cours */
  cours: {
    display: "flex",
    flexDirection: "column",
    py: 3,
    spacing: 0,
    backgroundColor: "#222",
    "& h2": {
      fontSize: "48px",
      color: HoplaTheme.custom.White,
      textAlign: "center",
    },
    "& hr": {
      opacity: 0.5,
    },
    "& .coursImgList": {
      height: "700px",
      [HoplaTheme.breakpoints.up("sm")]: {
        height: "430px",
      },
      justifyContent: "center",
      alignItems: "center",
      "& .MuiGrid-item": {
        height: "30%",
        padding: '0px 0px',
        [HoplaTheme.breakpoints.up("sm")]: {
          height: "100%",
        },
      },
      "& .coursImg": {
        backgroundSize: "cover",
        backgroundPosition: "center center",
        position: "relative",
        height: "100%",
        [HoplaTheme.breakpoints.down("sm")]: {
          margin: "0 !important",
        },
        "& .MuiBox-root": {
          visibility: "hidden",
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: "2px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          "& span": {
            fontSize: "30px",
            color: HoplaTheme.custom.White,
          },
        },
        "&:hover": {
          "& .MuiBox-root": {
            visibility: "visible",
          },
        },
      },
    },
  },
  /** Notre sélection */
  notreSelection: {
    display: "flex",
    flexDirection: "column",
    py: 3,
    "& .coach": {
      [HoplaTheme.breakpoints.up("xl")]: {
        width: "70%",
      },
      m: "auto",
      py: 3,
      px: 10,
      backgroundImage: `
      url('Vector11.svg'),
      url('Vector12.svg'),
      url('Vector12.svg'),
      url('Vector11.svg')`,
      backgroundPosition: `
      top 0px right 0px,
      top 45px right 45px,
      bottom 45px left 45px,
      bottom 0px left 0px`,
      backgroundRepeat: "no-repeat",
    },
    "& .MuiTypography-h4": {
      fontSize: "40px",
      color: HoplaTheme.palette.info.main,
    },
    "& .MuiTypography-body1": {
      my: 1,
      fontSize: "18px",
      "& span": {
      },
    },
  },
  /** Coach du mois */
  coachDuMois: {
    display: "flex",
    backgroundColor: '#afc9c9',
    p: 0,
    "& .MuiTypography-h4": {
      fontSize: "52px",
      color: "black",
    },
    "& .MuiTypography-h5": {
      fontSize: "26px",
    },
    "& .MuiTypography-body1": {
      my: 1,
      fontSize: "18px",
    },
    "& .MuiTypography-body2": {
      fontSize: "18px",
    },
    "& hr": {
      backgroundColor: HoplaTheme.palette.info.main,
      height: "2px",
      border: "none",
    },
  },
  coachDuMoisContainer: {
    position: "relative",
    backgroundColor: "transparent",
  },
  modalContent: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: 24,
    p: 4,
    "& .MuiTypography-body1": {
      my: 2,
    },
    "& .MuiTypography-h4": {
      fontSize: "20px",
    },
    "& .firstPrice": {
      mr: 2,
      fontSize: "22px",
      color: HoplaTheme.palette.secondary.main,
      position: "relative",
      "&::before": {
        position: "absolute",
        content: "''",
        left: 0,
        top: "50%",
        right: 0,
        borderTop: "1px solid",
        borderColor: HoplaTheme.custom.Black,
        "-webkit-transform": "rotate(-5deg)",
        "-moz-transform": "rotate(-5deg)",
        "-ms-transform": "rotate(-5deg)",
        "-o-transform": "rotate(-5deg)",
        transform: "rotate(-5deg)",
      },
    },
  },
  /** Page de connexion / inscription */
  login: {
    p: 4,
    width: "50%",
    m: "auto",
    backgroundColor: "rgba(11, 11, 12, 0.85)",
    [HoplaTheme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [HoplaTheme.breakpoints.down("sm")]: {
      width: "95%",
      p: 1,
    },
    "& .MuiTab-root": {
      px: 7,
      textTransform: "capitalize",
      fontSize: "18px",
      color: "#FFF",
      [HoplaTheme.breakpoints.down("sm")]: {
        p: 2,
      },
      "&.Mui-selected": {
        color: "#FFF",
        "&::before": {
          content: "''",
          display: "block",
          position: "absolute",
          bottom: 0,
          width: "25%",
          left: "35%",
          borderTop: "1px solid #FFF",
        },
      },
      "&:first-of-type": {
        borderRight: "1px solid #FFF",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .info": {
      color: "#FFF",
      fontStyle: "italic",
      opacity: 0.7,
    },
    "& .MuiFormGroup-root": {
      my: 3,
      "& .MuiCheckbox-root, .MuiFormControlLabel-label": {
        color: "#FFF",
      },
      "& .MuiInput-root": {
        fontSize: "18px",
        "&::before, &::after": {
          borderBottom: "2px solid #FFF !important",
        },
        "&:hover::before, &:hover::after": {
          borderBottom: "2px solid #FFF !important",
        },
        "& ::placeholder": {
          fontStyle: "italic",
          opacity: 0.7,
        },
      },
      "& .MuiInput-input": {
        color: "#FFF",
        borderBottom: "2px solid #FFF",
        "&:hover": {
          borderBottom: "2px solid #FFF",
        },
      },
      "& .passwordIndication": {
        color: "white",
      },
    },
    "& .MuiTypography-h6": {
      fontSize: "24px",
      textAlign: "center",
      color: "#FFF",
      [HoplaTheme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    "& .MuiButton-containedPrimary": {
      display: "flex",
      margin: "auto",
    },
    "& .MuiButton-textPrimary": {
      p: 0,
      my: 2,
      color: "#FFF",
      fontStyle: "italic",
      fontSize: "12px",
      opacity: 0.7,
    },
  },
  profileBloc: {
    py: 4,
    mt: 15,
    "& .MuiTypography-h2": {
      textAlign: "center",
      fontSize: "28px",
      color: HoplaTheme.custom.White,
    },
    "& .MuiTypography-h5": {
      my: 1,
      textAlign: "left",
      fontSize: "18px",
    },
    "& .profileClientBloc": {
      my: 2,
      p: 3,
      "& >.MuiBox-root": {
        my: 2,
        display: "flex",
        alignItems: "center",
        py: 3,
      },
      "& .MuiTypography-body2": {
        py: 1,
      },
      "& .MuiTextField-root": {
        width: "100%",
        "& .MuiInputBase-root": {
          fontSize: "14px",
        },
      },
    },
    "& .profileClientAvisBloc": {
      my: 2,
      p: 3,
      "& >.MuiBox-root:first-of-type": {
        my: 2,
        display: "flex",
        alignItems: "center",
        py: 3,
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        boxShadow: "1px 4px 15px 2px rgba(0, 0, 0, 0.3)",
        "& .comment": {
          p: 1.5,
          borderRadius: "20px",
          backgroundColor: "#D9D9D9",
          position: "relative",
          // "&::after": {
          //   content: '""',
          //   position: 'absolute',
          //   left:'-7px',
          //   top: '20px',
          //   width: 0,
          //   height: 0,
          //   borderStyle: 'solid',
          //   borderWidth: '7px 7.5px 0 7.5px',
          //   borderColor: '#042424 transparent transparent transparent'
          // }
        },
      },
    },
  },
  favoris: {
    position: "absolute",
    top: "0",
    right: "0",
    zIndex: 999,
    "& .MuiSvgIcon-root": {
      fontSize: "36px",
    },
  },
  packDescription: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-body1": {
      my: 2,
    },
    "& .MuiTypography-h4": {
      fontSize: "20px",
    },
    "& .firstPrice": {
      mr: 2,
      fontSize: "22px",
      color: HoplaTheme.palette.secondary.main,
      position: "relative",
      "&::before": {
        position: "absolute",
        content: "''",
        left: 0,
        top: "50%",
        right: 0,
        borderTop: "1px solid",
        borderColor: HoplaTheme.custom.Black,
        "-webkit-transform": "rotate(-5deg)",
        "-moz-transform": "rotate(-5deg)",
        "-ms-transform": "rotate(-5deg)",
        "-o-transform": "rotate(-5deg)",
        transform: "rotate(-5deg)",
      },
    },
    "& .newPrice": {
      fontSize: "22px",
      color: HoplaTheme.palette.primary.main,
    },
  },
  popupForm: {
    "& .wrapper": {
      p: 3,
      position: "relative",
      backgroundColor: "#FFF",
      boxShadow:
        "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
    },
    "& .MuiPaper-root": {
      [HoplaTheme.breakpoints.up("sm")]: {
        p: 6,
      },
      overflowY: "unset",
      overflowX: "unset",
      minWidth: "60vw",
      boxShadow: "none",
      backgroundColor: "transparent",
      [HoplaTheme.breakpoints.down("xl")]: {
        maxWidth: "80vw",
      },
      [HoplaTheme.breakpoints.down("md")]: {
        maxWidth: "90vw",
      },
      "& .content": {
        overflowY: "auto" as "auto",
        overflowX: "auto" as "auto",
        maxHeight: "calc(100% - 64px)",
      },
    },
    "& .MuiButton-containedPrimary": {
      backgroundColor: HoplaTheme.palette.primary.main,
      "&:hover": {
        backgroundColor: HoplaTheme.palette.primary.main,
      },
    },
    "& .MuiButton-outlinedPrimary": {
      borderColor: HoplaTheme.palette.primary.main,
      color: HoplaTheme.palette.primary.main,
    },
  },
  closeBtn: {
    position: "absolute",
    backgroundColor: HoplaTheme.custom.White,
    top: "-15px",
    right: "-13px",
    p: 0,
    color: "red",
    zIndex: 9999,
    border: `2px solid ${HoplaTheme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: HoplaTheme.custom.White,
    },
  },
  image: {
    m: "auto",
    padding: 1,
    position: "relative",
    width: "150px",
    height: "150px"
  },
  responsiveImg: {
    width: "150px",
    height: "150px",
    [HoplaTheme.breakpoints.up("sm")]: {
      width: "200px",
      height: "200px",
    },
    [HoplaTheme.breakpoints.up("lg")]: {
      width: "300px",
      height: "300px",
    },
    [HoplaTheme.breakpoints.up("xl")]: {
      width: "350px",
      height: "350px",
    },
  },
  small: {
    width: "100px",
    height: "100px",
  },
  cookies: {
    background: "rgb(255, 255, 255)",
    color: "#000",
    "& button:first-of-type": {
      backgroundColor: "green !important",
    },
  },
  faq: {
    py: 4,
    "& .MuiTypography-h2": {
      my: 3,
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "48px",
      color: "white",
      textShadow: "black 0.1em 0.1em 0.2em",
    },
    "& .introduction": {
      my: 2,
      backgroundColor: HoplaTheme.custom.White,
      borderRadius: "10px",
      "& .MuiTypography-root:first-of-type": {
        my: 1,
        color: HoplaTheme.custom.Black,
        fontSize: "14",
        textTransform: "uppercase",
      },
    },
  },
  defiscalisation: {
    py: 4,
    "& .MuiTypography-h2": {
      my: 3,
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "48px",
      color: "white",
      textShadow: "black 0.1em 0.1em 0.2em",
    },
    "& .introduction": {
      my: 2,
      p: 3,
      backgroundColor: HoplaTheme.custom.White,
      borderRadius: "20px",
      "& .MuiTypography-root:first-of-type": {
        my: 1,
        color: HoplaTheme.custom.Black,
        fontSize: "22px",
        textTransform: "uppercase",
      },
    },
    "& .defisc": {
      my: 2,
      p: 3,
      backgroundColor: HoplaTheme.custom.White,
      borderRadius: "70px",
      "& .MuiTypography-root:first-of-type": {
        my: 1,
        color: HoplaTheme.custom.Black,
        fontSize: "22px"
      },
    },
    "& .etapes": {
      "& .etape": {
        p: 3,
        "& .MuiTypography-h3": {
          my: 3,
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: "48px",
          color: "white",
          textShadow: "black 0.1em 0.1em 0.2em",
        },
        "& .MuiGrid-container": {
          px: 3,
          py: 1.5,
          backgroundColor: HoplaTheme.custom.White,
          borderRadius: "20px",
          "& .MuiTypography-body2": {
            my: 2,
            fontSize: "22px",
          },
          "& .MuiTypography-body1": {
            fontSize: "18px",
          },
          "& img": {
            maxWidth: "100%",
          },
        },
      },
    },
  },
  chipCustomStyle: {
    my: 1,
    mx: 0.3,
    backgroundColor: HoplaTheme.palette.primary.main,
    color: HoplaTheme.custom.White,
    "& .MuiSvgIcon-root, .MuiSvgIcon-root:hover": {
      color: HoplaTheme.custom.White,
    },
  },
  coachMainPage: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: '#a2c2c2',
    marginTop: {
      xs: '-15px',
      sm: '150px'
    },
    "& .MuiTab-root": {
      my: 2,
      px: 7,
      textTransform: "capitalize",
      fontSize: "18px",
      color: "black",
      [HoplaTheme.breakpoints.down("sm")]: {
        p: 2,
      },
      "&.Mui-selected": {
        color: "black",
        "&::before": {
          content: "''",
          display: "block",
          position: "absolute",
          bottom: 0,
          width: "25%",
          left: "35%",
          borderTop: "1px solid black",
        },
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  coachPlanning: {
    my: 2,
    padding: "24px !important",
    borderRadius: "20px",
    boxShadow: "1px 4px 15px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
  planningLegend: {
    py: 2,
    "& .MuiGrid-item": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  planningLegendItem: {
    height: "40px",
    width: "120px",
    marginRight: "5px",
  },
  table: {
    overflowY: "hidden",
    padding: 1,
    "& td, & th": {
      width: "1px",
      padding: "1px",
      margin: "1px",
      whiteSpace: "nowrap",
      "&.sticky": {
        // position: "sticky",
        // left: 0,
        // [HoplaTheme.breakpoints.down(1200)]: {
        //   backgroundColor: "white",
        //   borderRight: "1px solid black",
        // },
      },
    },
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  mail: {
    backgroundColor: HoplaTheme.palette.info.main,
    backgroundImage: `url('wave-mail.svg')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: `bottom 0 right 0`,
  },
  textarea: {
    "& textarea": {
      width: "100%",
      marginTop: "16px",
      marginBottom: "16px",
      padding: "16px",
      color: HoplaTheme.custom.Black,
      border: "2px solid #8C6AD4",
      backgroundColor: "transparent",
      fontSize: "16px",
      "&::placeholder": {
        fontSize: "16px",
      },
    },
    "& .MuiButtonBase-root": {
      backgroundColor: HoplaTheme.palette.secondary.main,
      ml: 2,
      "& .MuiSvgIcon-root": {
        color: HoplaTheme.custom.White,
      },
    },
  },
  indication: {
    color: "red",
    fontSize: "14px",
    fontStyle: "italic",
  },
};
export default classes;
