import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import classes from "../style";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";

export const Faq: React.FC = () => {
  var items = [
    {
      title: 'Qu’est ce que l’Avance Immédiate ?',
      body: 'L’Avance Immédiate est un service gratuit proposé par l’URSSAF.<br/>Vous bénéficiez d’un crédit d’impôt de 50 % pour toutes vos activités de service à la personne.<br/>En clair, vous ne faites plus d’avance de trésorerie. Vous bénéficiez de votre crédit d’impôt au moment de la dépense, sans attendre l’année suivante !<br/>https://www.urssaf.fr/accueil/services/services-particuliers/service-avance-immediate.html'
    },
    {
      title: 'Pourquoi créer un compte URSSAF ?',
      body: 'La création de ce compte est nécessaire afin de bénéficier de votre crédit d’impot de 50 % sur vos séances de coaching Hoplà Coaching.'
    },
    {
      title: 'J’ai déjà un compte URSSAF pourquoi en créer un autre ?',
      body: 'La création du compte URSSAF depuis notre site nous permet de nous mettre en lien direct avec l’ Avance Immédiate.'
    },
    {
      title: 'Est ce que je peux payer en espèces ?',
      body: 'Non, vous êtes prélevé par l’URSSAF directement.'
    },
    {
      title: 'Est ce que je peux annuler ma séance ?',
      body: 'Oui, vous pouvez annuler la séance dans votre espace « Mon profil »'
    },
    {
      title: 'Est ce que je dois acheter du matériel ?',
      body: 'Il n’est pas nécessaire d’acheter du matériel pour vos séances, votre coach sportif vous le fournira.'
    }
  ];

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "calc(100vh - 96px)",
        display: "flex",
        backgroundImage: "url('login-bg.jpg')",
        backgroundSize: "cover",
        py: 2,
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"
      }}
    >
      <Box sx={classes.faq}>
        <Typography variant="h2">
          FAQ
        </Typography>
        <Box className="introduction">
          {
            // items.map(item => <Accordion enabled={expanded == item.title} onChange={() => handleChange(item.title)}>
            //   <AccordionSummary
            //     expandIcon={<ExpandMoreIcon />}>
            //     <Typography>{item.title}</Typography>
            //   </AccordionSummary>
            //   <AccordionDetails>
            //     <Typography><div dangerouslySetInnerHTML={{ __html: item.body }} /></Typography>
            //   </AccordionDetails>
            // </Accordion>)
          }
        </Box>
      </Box>
    </Container>
  );
};
